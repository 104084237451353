div.content {
  padding-top: 0;
}
.Quiz {
  display: flex;
  /* height: 100%; */
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: var(--darkAccent);
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
}

.Quiz > div:nth-child(2) {
  padding-top: 10px;
  padding-bottom: 50px;
}

.Quiz button.rpcc-play-button {
  background-color: var(--yellowAccent);
}

.rpcc-ring__duration {
  stroke: var(--yellowAccent) !important;
}

.Quiz div.options button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
  background-color: var(--greyAccent);
  border-radius: 1em;
  font-weight: 800;
  font-size: medium;
  cursor: pointer;
  border-color: rgb(0, 0, 0, 0.1);
  transition: opacity 100ms ease-in;
}
button.playbtn,
button.leaderbtn {
  padding-left: 1em;
}
.Quiz button:hover {
  background-color: var(--darkerGrey);
}
.Quiz h1.questionTitle {
  margin-top: 0;
  font-size: medium;
  padding-bottom: 0;
  margin-bottom: 0;
}
div.questionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
p.difficulty {
  padding: 0.2em 1em;
  font-family: "Poppins";
  color: var(--lightAccent);
  border-radius: 20px;
  margin-bottom: 1em;
  max-width: 200px;
}
p.difficulty.easy {
  background: #1d7874;
}
p.difficulty.medium {
  background: var(--yellowAccent);
  color: var(--darkAccent);
}
p.difficulty.hard {
  background: var(--redAccent);
}

.questionTitle {
  text-align: center;
  font-weight: 900;
  margin: auto;
  margin: 2rem;
  /* margin-right: 3rem; */
  /* margin-right: 3rem; */

  color: var(--lightAccent);
  overflow: hidden;
}

.ellipseOption {
  width: 50px;
  margin-right: 20px;
}

@media (min-width: 600px) {
  .Quiz h1.questionTitle {
    font-size: xx-large;
  }
  .Quiz {
    justify-content: space-around;
  }
}

/* Base styles for Popover across all devices */
.ant-popover-inner {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 60vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ant-popover-inner-content {
  padding: 16px !important;
}

.translation-popup {
  padding: 8px;
  max-width: 300px;
  scroll-behavior: smooth;
  max-height: calc(60vh - 32px);
  overflow-y: auto;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .ant-popover {
    width: 85% !important;
    max-width: 280px !important;
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 20px !important;
  }
  /* .Quiz .rpcc-time {
    font-size: 0.8em !important;
  } */
  
  .Quiz .rpcc-ring {
    transform: scale(0.9);
  }
}

/* Tablet and desktop adjustments */
@media (min-width: 601px) {
  .ant-popover {
    max-width: 400px !important;
  }
  
  .translation-popup {
    max-width: 360px;
  }
}
