@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/*----- Global CSS -----*/
/* 
font-family: 'Poppins', sans-serif; - for all text, adjusting font-weight as you need to
font-family: 'Roboto Mono', monospace; - for bomb countdown
*/

* {
  box-sizing: border-box;
}

/*----- Colours -----*/
/* All colours can be used using var(--colourYouWant) */

:root {
  /* Main colors */
  --lightAccent: #ffffff; /* white */
  --darkAccent: #363537; /* jet */
  --redAccent: #bf211e; /* venetian red */
  --orangeAccent: #ff8c42; /* mango tango */
  --yellowAccent: #e9ce2c; /* safety yellow */
  --greyAccent: #c4c4c4; /* lighter grey */
  --darkerGrey: #8b939c; /* darker grey for hover */
  --darkerOrange: #e67329; /* darker orange for hover */
}

[data-theme="light"] {
  --lightAccent: #363537; /* white */
  --darkAccent: #fff; /* jet */
  --redAccent: #bf211e; /* venetian red */
  --orangeAccent: #ff8c42; /* mango tango */
  --yellowAccent: #e9ce2c; /* safety yellow */
  --greyAccent: #242121; /* lighter grey */
  --darkerGrey: #8b939c; /* darker grey for hover */
  --darkerOrange: #e67329; /* darker orange for hover */
}

[data-theme="dark"] {
  --lightAccent: #ffffff; /* white */
  --darkAccent: #363537; /* jet */
  --redAccent: #bf211e; /* venetian red */
  --orangeAccent: #ff8c42; /* mango tango */
  --yellowAccent: #e9ce2c; /* safety yellow */
  --greyAccent: #c4c4c4; /* lighter grey */
  --darkerGrey: #8b939c; /* darker grey for hover */
  --darkerOrange: #e67329; /* darker orange for hover */
}
/*----- Global Styles -----*/
.ant-btn-primary {
  background: var(--darkerOrange);
  /* border-color: #yourColor; */
}

.ant-btn-primary:hover {
  background: var(--darkerOrange);
  /* border-color: #yourColor; */
}
.theme-toggle i {
  font-size: 2rem;
  cursor: pointer;
}

.theme-toggle {
  text-align: center;
}

.app {
  /* background-color: var(--darkAccent); */
  color: var(--lightAccent);
  background: var(--darkAccent);
  margin: 0 auto;
  line-height: 1.4;
  padding: 2.9em 0;
  /* width: 90%; */
  /* height: 100%; */

  /* min-height: 100vh; */
  font-family: "Poppins", sans-serif;
  /* background-repeat: no-repeat; */
  /* background-position-y: 35%; */
  /* background-position-x: -40%; */
  /* background-size: cover; */

  /* color: var(--lightAccent); */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background: var(--darkAccent); */
  /* height: 100%; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8rem;
  transition: all 0.5s;  */
  /* height: 126vh; */
  /* position: fixed; */
  overflow: auto;
  height: 100%;
}
html,
body,
#root,
.app {
  height: 100%;
}
body {
  zoom: 0.8;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--lightAccent);
  padding-bottom: 1em;
}

p {
  margin: 0;
  padding-bottom: 2em;
}

a {
  text-decoration: none;
  color: var(--lightAccent);
}

a:hover,
.navbar-selected {
  text-decoration: none;
  color: var(--orangeAccent);
  /* font-weight: 600; */
}

ul {
  list-style-type: none;
}

/* ----- Fonts -----*/

h1 {
  font-size: 1.802rem;
}

h2 {
  font-size: 1.602rem;
}

h3 {
  font-size: 1.424rem;
}

/* ----- Buttons ----- */

.btn {
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  border: none;
}

.btn-primary {
  background: var(--orangeAccent);
  color: var(--lightAccent);
}

.btn-primary:hover {
  background: var(--darkerOrange);
}

.btn-secondary {
  background: var(--greyAccent);
  color: var(--lightFont);
}

.btn-secondary:hover {
  background: var(--darkerGrey);
}

/* ----- Box Shadow ----- */

.form-input,
.btn {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3);
}

.avatarImg-uploader {
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
}

/* ----- Layouts ----- */

.content {
  /* width: 90%; */

  margin: 0 auto;
  padding: 2em 0;
}

.height-auto {
  height: auto !important;
}
/* ----- Forms ----- */

.form-label {
  color: var(--lightAccent);
}

.form-input {
  font-family: "Poppins", sans-serif;
  color: var(--darkAccent);
  border-radius: 10px;
}

.ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item-label > label {
  color: var(--lightAccent);
  font-size: 1rem;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  content: "*";
}

form p {
  padding: 2em 0;
}

.form-text {
  color: var(--lightAccent);
}

.form-link {
  color: var(--orangeAccent);
}

.form-link:hover {
  color: var(--darkerOrange);
}

form .btn {
  margin-top: 1em;
}
.custom-file-input {
  width: 2rem;
}
/* .ant-btn:not([disabled]):hover {
  text-decoration: none;
  color: #e67329;
} */

/* ----- Media Queries ----- */

@media (max-width: 400px) {
  html {
    font-size: 1.125rem;
  }

  /* .app { */
    /* width: 80%; */
    /* max-width: 1200px; */
    /* margin: 0 auto; */
    /* position: fixed; */
    /* overflow: auto;
    height: 100%; */
  /* } */
}

/* ----- Ant Design Form Label/Title Global Styles ----- */
.ant-form-item-label > label {
  color: var(--lightAccent) !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  padding-bottom: 8px !important;
}

/* Add placeholder and input text styling for Ant Design inputs */
.ant-input::placeholder,
.ant-input {
  font-size: 0.9rem !important;
}

/* Required asterisk styling */
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block !important;
  margin-right: 4px !important;
  color: var(--redAccent) !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 1 !important;
  content: "*" !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: none !important;
}

/* Form item spacing */
.ant-form-item {
  margin-bottom: 1.2rem !important;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .ant-form-item-label > label {
    font-size: 0.8rem !important;
  }
}

/* Add this to change the text selection color */
::selection {
  background: var(--darkerOrange);
  color: var(--lightAccent);
}

/* For Firefox */
::-moz-selection {
  background: var(--darkerOrange);
  color: var(--lightAccent);
}

div.content {
  padding-top: 0;
}
.Quiz {
  display: flex;
  /* height: 100%; */
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: var(--darkAccent);
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
}

.Quiz > div:nth-child(2) {
  padding-top: 10px;
  padding-bottom: 50px;
}

.Quiz button.rpcc-play-button {
  background-color: var(--yellowAccent);
}

.rpcc-ring__duration {
  stroke: var(--yellowAccent) !important;
}

.Quiz div.options button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
  background-color: var(--greyAccent);
  border-radius: 1em;
  font-weight: 800;
  font-size: medium;
  cursor: pointer;
  border-color: rgb(0, 0, 0, 0.1);
  transition: opacity 100ms ease-in;
}
button.playbtn,
button.leaderbtn {
  padding-left: 1em;
}
.Quiz button:hover {
  background-color: var(--darkerGrey);
}
.Quiz h1.questionTitle {
  margin-top: 0;
  font-size: medium;
  padding-bottom: 0;
  margin-bottom: 0;
}
div.questionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
p.difficulty {
  padding: 0.2em 1em;
  font-family: "Poppins";
  color: var(--lightAccent);
  border-radius: 20px;
  margin-bottom: 1em;
  max-width: 200px;
}
p.difficulty.easy {
  background: #1d7874;
}
p.difficulty.medium {
  background: var(--yellowAccent);
  color: var(--darkAccent);
}
p.difficulty.hard {
  background: var(--redAccent);
}

.questionTitle {
  text-align: center;
  font-weight: 900;
  margin: auto;
  margin: 2rem;
  /* margin-right: 3rem; */
  /* margin-right: 3rem; */

  color: var(--lightAccent);
  overflow: hidden;
}

.ellipseOption {
  width: 50px;
  margin-right: 20px;
}

@media (min-width: 600px) {
  .Quiz h1.questionTitle {
    font-size: xx-large;
  }
  .Quiz {
    justify-content: space-around;
  }
}

/* Base styles for Popover across all devices */
.ant-popover-inner {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 60vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ant-popover-inner-content {
  padding: 16px !important;
}

.translation-popup {
  padding: 8px;
  max-width: 300px;
  scroll-behavior: smooth;
  max-height: calc(60vh - 32px);
  overflow-y: auto;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .ant-popover {
    width: 85% !important;
    max-width: 280px !important;
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 20px !important;
  }
  /* .Quiz .rpcc-time {
    font-size: 0.8em !important;
  } */
  
  .Quiz .rpcc-ring {
    transform: scale(0.9);
  }
}

/* Tablet and desktop adjustments */
@media (min-width: 601px) {
  .ant-popover {
    max-width: 400px !important;
  }
  
  .translation-popup {
    max-width: 360px;
  }
}

header {
  padding: 1em 1em;
}

.navbar {
  /* position: relative; */
  position: relative;
}

.app-logo {
  position: absolute;
  left: 1em;
  top: 0.7em;
  z-index: 10;
  cursor: pointer;
}

.navbar button {
  position: fixed;
  text-align: right;
  right: 1em;
  top: 1em;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  border: none;
}

.navbar-icon {
  font-size: 1.8rem;
}

.menuNav {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  z-index: 9;
  background-color: var(--darkerGrey);
  transition: width 400ms cubic-bezier(0.175, 0.885, 0.32, 1.475);
}

.menuNav.showMenu {
  width: 100%;
}

.menuNav a:first-child {
  margin-top: 5em;
}

.navbar-item {
  padding: 1em;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.navbar a {
  display: block;
}
/* .form-input {
  width: "450px";
} */
@media (min-width: 500px) {
  header {
    padding: 1.5em 2em;
  }
  /* .form-input {
    width: "450px";
    margin-left: 2rem;
    margin-right: 2rem;
  } */
  .navbar button {
    right: 2em;
    top: 1.5em;
  }

  .navbar-icon {
    font-size: 2.2rem;
  }

  .app-logo {
    left: 2em;
    top: 1.5em;
  }
}

@media (min-width: 1000px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
  }

  .navbar {
    align-self: flex-end;
    margin-bottom: 0.5em;
    margin-top: 0.4rem;
    margin-right: 2.5em;
  }

  .navbar button {
    display: none;
  }

  .menuNav {
    position: relative;
    height: auto;
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    background-color: inherit;
  }

  .menuNav a:first-child {
    margin-top: 0;
  }

  .navbar-item {
    width: auto;
    padding: 0;
    margin-right: 0.8em;
    font-size: 1rem;
  }

  .navbar a {
    display: inline-block;
  }

  .app-logo {
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    cursor: pointer;
  }
}

@media (max-width: 998px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--darkAccent);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 27px;
  }
  .app {
    padding-top: 4.7em;
  }
  .navbar {
    height: 38.4px;
    display: flex;
    align-items: center;
    margin-top: 13.5px;
  }

  .navbar button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38.4px;
  }

  .navbar-icon {
    font-size: 1.8rem;
    color: var(--lightAccent);
  }

  /* Adjust app padding to match header height */
  /* .app {
    padding-top: 72px;
  } */

  .menuNav a,
  .menuNav div {
    display: block;
    margin: 0;  /* Reset all margins */
    padding: 1rem;  /* Use padding for spacing */
  }

  /* Target DropdownOptions specifically */
  .menuNav div.navbar-item {
    margin: 0;
    padding: 0;  /* Remove padding from the container */
  }

  /* First item spacing from top */
  .menuNav a:first-child {
    margin-top: 5rem;
  }

  /* Remove any inherited margins */
  .navbar-item {
    margin: 0;
  }

  /* Target dropdown options buttons/links specifically */
  .menuNav div.navbar-item button,
  .menuNav div.navbar-item a {
    padding: 1rem;
    margin: 0;
  }

  .menuNav {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    overflow: hidden;
    z-index: 9;
    background-color: var(--darkerGrey);
    display: flex;
    flex-direction: column;
  }

  .menuNav.showMenu {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;  /* Add padding at bottom */
    overflow-y: auto;      /* Enable scrolling when needed */
  }

  .menuNav-main {
    flex: 1 1;
    padding-top: 5em;
    display: flex;
    flex-direction: column;
    min-height: min-content;
  }

  .menuNav-bottom {
    padding-bottom: 2em;
    margin-top: auto;  /* Push to bottom */
  }

  .menuNav a:first-child {
    margin-top: 5em;  /* Reduced from 5em */
  }

  .menuNav a,
  .menuNav div {
    padding: 0.8rem;
  }
}

.logoStyle {
  display: flex;
  align-items: center;
  height: 38.4px;
}

.logoStyle a {
  display: flex;
  align-items: center;
  text-decoration: none;
  grid-gap: 4px;
  gap: 4px;
  height: 100%;
  margin-left: 4em;
}

.mainLogo {
  font-size: 2.5rem;
  color: var(--darkerOrange);
  font-family: 'Great Vibes', cursive;
}

.subLogoHSK {
  font-size: 1.8rem;
  color: var(--accentColor);
  font-family: 'Arial', sans-serif;
}

@media (max-width: 998px) {
  .logoStyle a {
    margin-left: 0;
  }
  
  .mainLogo {
    font-size: 1.8rem;
    color: var(--darkerOrange);
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .subLogoHSK {
    font-size: 1.6rem;
    color: var(--lightAccent);
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 430px) {
  .logoStyle {
    font-size: 1.4rem;
    padding-left: 0.2em;
  }
  
  .mainLogo {
    font-size: 2rem;
  }
  
  .subLogoHSK {
    font-size: 1.5rem;
  }
}

/* Add this to App.css */
/* .app {
  padding-top: 80px;
} */

.signup-container {
  padding: 1.5rem;
  max-width: 450px;
  margin: 1.5rem auto;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transform: translateZ(0);
  will-change: transform;
  -webkit-tap-highlight-color: transparent;
}

/* Title styling */
.signup-container h1 {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
}

/* Form item spacing */
.ant-form-item {
  margin-bottom: 0.8rem !important;
}

/* Button styling */
.btn.btn-primary {
  background: linear-gradient(45deg, #e67329, #ff8f4d) !important;
  border: none !important;
  height: 40px !important;
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
}

.btn.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(75, 204, 163, 0.2) !important;
}

/* Sign up text styling */
.form-text {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem !important;
  margin-top: 0.5rem !important;
}

.form-link {
  color: #e67329;
  font-weight: 600;
}

.form-link:hover {
  color: #ff8f4d;
  text-decoration: underline;
}

/* Desktop-specific styles */
@media (hover: hover) and (pointer: fine) {
  .signup-container {
    transition: all 0.3s ease;
  }

  .btn.btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(75, 204, 163, 0.2) !important;
  }

  .form-link:hover {
    color: #ff8f4d;
    text-decoration: underline;
  }
}

/* Mobile and touch device optimizations */
@media (hover: none) and (pointer: coarse) {
  .signup-container {
    margin: 1rem auto;
    transition: none !important;
    -webkit-backdrop-filter: none;
            backdrop-filter: none; /* Disable for better performance */
    background: rgba(255, 255, 255, 0.15); /* Solid background fallback */
  }

  .btn.btn-primary,
  .form-link {
    transition: none !important;
    transform: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  .btn.btn-primary:active {
    background: #e67329 !important;
    transform: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  .form-link:active {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .signup-container {
    backface-visibility: hidden;
    perspective: 1000;
  }

  .ant-form-item,
  .ant-input,
  .btn.btn-primary {
    transform: translateZ(0);
  }

  /* Prevent scroll issues */
  .ant-form {
    -webkit-overflow-scrolling: touch;
  }
}

/* Small screen specific adjustments */
@media (max-width: 380px) {
  .signup-container {
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 12px;
  }

  .signup-container h1 {
    font-size: 1.8rem;
  }

  .ant-form-item {
    margin-bottom: 0.75rem !important;
  }

  .btn.btn-primary {
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
  }
}

/* Add this to style the placeholder text */
.ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}


.login-container {
  padding: 2rem;
  max-width: 470px;
  margin: 2rem auto;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transform: translateZ(0);
  will-change: transform;
  -webkit-tap-highlight-color: transparent;
}

/* Title styling */
.login-container h1 {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
}

/* Form item spacing */
.ant-form-item {
  margin-bottom: 1rem !important;
}

/* Button styling */
.ant-btn-primary {
  background: linear-gradient(45deg, #e67329, #ff8f4d) !important;
  border: none !important;
  height: 40px !important;
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 2rem !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  grid-gap: 8px !important;
  gap: 8px !important;
}

.ant-btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(75, 204, 163, 0.2) !important;
}

/* Sign up text styling */
.form-text {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem !important;
  margin-top: 0.5rem !important;
}

.form-link {
  color: #e67329;
  font-weight: 600;
  transition: color 0.3s ease;
}

.form-link:hover {
  color: #ff8f4d;
  text-decoration: underline;
}

/* Mobile adjustments */
@media (max-width: 380px) {
  .login-container {
    margin: 0.5rem;
    padding: 1rem;
  }
}

.qrcode-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.qrcode-wrapper {
  margin: 20px 0;
}

.qrcode-placeholder {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border: 1px solid var(--border-color, #eee);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color, #fff);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Remove the h1 from the PasswordLogin component since it's now in the QRCodeLogin */
.login-container h1 {
  margin-bottom: 24px;
  text-align: center;
}

/* Tab styling */
.ant-tabs {
  color: rgba(255, 255, 255, 0.8) !important;
}

.ant-tabs-nav {
  margin-bottom: 2rem !important;
}

.ant-tabs-tab {
  padding: 8px 0 !important;
  font-size: 1rem !important;
  color: rgba(255, 255, 255, 0.6) !important;
  transition: color 0.3s ease !important;
}

.ant-tabs-tab:hover {
  color: #ff8f4d !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #e67329 !important;
  font-weight: 600 !important;
}

.ant-tabs-ink-bar {
  background: linear-gradient(45deg, #e67329, #ff8f4d) !important;
  height: 3px !important;
}

/* QR code specific styling */
.qrcode-placeholder {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  border-radius: 12px !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1) !important;
}

.qrcode-container .anticon-wechat {
  color: #e67329 !important;
  font-size: 64px !important;
}

.google-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding: 1rem;
  width: 100%;
}

/* Desktop styling */
@media (min-width: 768px) {
  .google-login-container > div {
    width: 100% !important;
    max-width: 350px !important;
    transform: scale(1.2);
    margin: 20px 0;
    transition: all 0.3s ease;
  }

  .google-login-container > div:hover {
    opacity: 0.9;
    transform: scale(1.22);
  }
}

/* Mobile styling */
@media (max-width: 767px) {
  .google-login-container {
    min-height: 100px;
    padding: 0.5rem;
  }

  .google-login-container > div {
    width: 100% !important;
    transform: scale(1) !important;
    margin: 10px 0;
    transition: none !important;
  }

  .google-login-container > div:hover,
  .google-login-container > div:active {
    transform: none !important;
    opacity: 1 !important;
  }
  
  /* Ensure button fits mobile screens */
  .google-login-container iframe {
    width: 100% !important;
    max-width: 300px !important;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .google-login-container > div {
    -webkit-tap-highlight-color: transparent;
    backface-visibility: hidden;
  }
}

/* Style for the Google tab */
.ant-tabs-tab .anticon-google {
  color: #e67329;
  margin-right: 8px;
  font-size: 16px;
}

.wechat-login-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.ant-tabs-nav-list {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
}

/* Remove the previous tab-specific margins */
.ant-tabs-tab:first-child,
.ant-tabs-tab:nth-child(2),
.ant-tabs-tab:last-child {
  margin: 0 !important;
}

/* Add minimum width to ensure text visibility */
.ant-tabs-tab {
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  padding: 8px 16px !important;
}

/* Ensure tab text doesn't wrap */
.ant-tabs-tab .ant-tabs-tab-btn {
  white-space: nowrap !important;
}

/* Hide the more dropdown button */
.ant-tabs-nav-more {
  display: none !important;
}

/* Tab container styling */
.ant-tabs-nav-list {
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  overflow: hidden !important;
}

.ant-tabs-tab {
  flex: 1 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  transition: all 0.3s ease-in-out !important;
  position: relative !important;
  overflow: hidden !important;
  min-width: 0 !important;
}

/* Active tab styling */
.ant-tabs-tab-active {
  flex: 3 1 !important; /* Takes more space when active */
}

/* Inactive tab styling */
.ant-tabs-tab:not(.ant-tabs-tab-active) {
  flex: 1 1 !important;
  opacity: 0.7 !important;
}

/* Tab button text */
.ant-tabs-tab .ant-tabs-tab-btn {
  white-space: nowrap !important;
  transition: all 0.3s ease-in-out !important;
  position: relative !important;
  width: 100% !important;
  text-align: center !important;
}

/* Active tab text */
.ant-tabs-tab-active .ant-tabs-tab-btn {
  transform: scale(1.1) !important;
}

/* Inactive tab text */
.ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
  transform: scale(0.9) !important;
}

/* Ink bar animation */
.ant-tabs-ink-bar {
  transition: all 0.3s ease-in-out !important;
}

/* Base login container styles */
.login-container {
  transform: translateZ(0);
  will-change: transform;
  -webkit-tap-highlight-color: transparent;
}

/* Desktop-specific styles */
@media (hover: hover) and (pointer: fine) {
  .login-container {
    transition: all 0.3s ease;
  }

  .ant-btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(75, 204, 163, 0.2) !important;
  }

  .form-link:hover {
    color: #ff8f4d;
    text-decoration: underline;
  }

  .ant-tabs-tab:hover {
    color: #ff8f4d !important;
  }
}

/* Mobile and touch device optimizations */
@media (hover: none) and (pointer: coarse) {
  .login-container {
    margin: 1rem auto;
    transition: none !important;
    -webkit-backdrop-filter: none;
            backdrop-filter: none; /* Disable for better performance */
    background: rgba(255, 255, 255, 0.15); /* Solid background fallback */
  }

  .ant-btn-primary,
  .form-link,
  .ant-tabs-tab {
    transition: none !important;
    transform: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  .ant-btn-primary:active {
    background: #e67329 !important;
    transform: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  .form-link:active {
    opacity: 0.7;
    text-decoration: none;
  }

  .ant-tabs-tab:active {
    opacity: 0.7;
  }

  /* Remove animations */
  .ant-tabs-ink-bar {
    transition: none !important;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .login-container {
    backface-visibility: hidden;
    perspective: 1000;
  }

  .ant-form-item,
  .ant-input,
  .ant-btn-primary {
    transform: translateZ(0);
  }

  /* Prevent scroll issues */
  .ant-tabs-content {
    -webkit-overflow-scrolling: touch;
  }
}

/* Small screen specific adjustments */
@media (max-width: 380px) {
  .login-container {
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 12px;
  }

  .ant-form-item {
    margin-bottom: 0.75rem !important;
  }

  .ant-btn-primary {
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
  }
}

.select-hsk-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .select-hsk-container p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .hsk-form {
    text-align: center;
  }
  
  .hsk-form h1 {
    margin-bottom: 20px;
  }
  
  .hsk-form p {
    margin-bottom: 30px;
    color: #666;
  }
  
  .ant-select {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  .ant-select-selector {
    background: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    -webkit-backdrop-filter: blur(10px) !important;
            backdrop-filter: blur(10px) !important;
    height: 50px !important;
    padding: 8px 16px !important;
  }
  
  .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 1.1rem !important;
    line-height: 34px !important;
  }
  
  .select-hsk-container .ant-btn-primary {
    background: linear-gradient(45deg, #e67329, #ff8f4d) !important;
    border: none !important;
    height: 50px !important;
    width: 100% !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    transition: all 0.3s ease !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    grid-gap: 8px !important;
    gap: 8px !important;
  }
  
  .select-hsk-container .ant-btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(230, 115, 41, 0.2) !important;
  }
  
  .ant-select-dropdown {
    background: rgba(40, 40, 40, 0.95) !important;
    -webkit-backdrop-filter: blur(10px) !important;
            backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding: 8px !important;
  }
  
  .ant-select-item {
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 1.1rem !important;
    padding: 12px 16px !important;
    height: auto !important;
  }
  
  .ant-select-item-option-selected {
    background: rgba(230, 115, 41, 0.2) !important;
    font-weight: 600 !important;
  }
  
  .ant-select-item-option-active {
    background: rgba(230, 115, 41, 0.1) !important;
  }
  
  .ant-modal-title {
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 1.5rem !important;
    text-align: center !important;
  }
  
  .ant-modal-content {
    background: rgba(40, 40, 40, 0.95) !important;
    -webkit-backdrop-filter: blur(10px) !important;
            backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  
  /* Modal styling */
  .ant-modal .ant-modal-content {
    background: rgba(40, 40, 40, 0.95) !important;
    -webkit-backdrop-filter: blur(10px) !important;
            backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 20px !important;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3) !important;
  }
  
  /* Modal header styling */
  .ant-modal .ant-modal-header {
    background: transparent !important;
    border-bottom: none !important;
    padding: 24px 24px 0 !important;
  }
  
  /* Modal title styling */
  .ant-modal .ant-modal-title {
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 1.5rem !important;
    text-align: center !important;
    font-weight: 600 !important;
    background: linear-gradient(45deg, #e67329, #ff944d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Modal body styling */
  .ant-modal .ant-modal-body {
    padding: 24px !important;
  }
  
  /* Info icon styling */
  .ant-modal .anticon-info-circle {
    color: #e67329 !important;
  }
  
  /* Select dropdown in modal */
  .ant-modal .ant-select-selector {
    background: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 8px !important;
  }
  
  /* Select dropdown text */
  .ant-modal .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.9) !important;
  }
  
  /* Button in modal */
  .ant-modal .ant-btn-primary {
    background: linear-gradient(45deg, #e67329, #ff8f4d) !important;
    border: none !important;
    border-radius: 8px !important;
  }
  
  /* Modal background overlay */
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
    -webkit-backdrop-filter: blur(5px) !important;
            backdrop-filter: blur(5px) !important;
  }


.scoreboard-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
}
.scoreboard-container button.ant-dropdown-trigger {
  align-self: flex-end;
  margin-bottom: 1em;
}

.leadHeader {
  color: white;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1em;
}

.podium {
  display: flex;
  height: 140px;
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 3em;
  justify-content: space-between;
  padding-right: 1em;
  align-self: center;
  flex-shrink: 0;
}
.podium-1st {
  order: 2;
  align-self: start;
}
.podium-2nd {
  order: 1;
  align-self: end;
}
.podium-3rd {
  order: 3;
  align-self: end;
}

/* table styles */
div.tableScore div.ant-table-wrapper {
  max-width: 600px;
  margin: auto;
}


table thead.ant-table-thead tr th {
  background: var(--darkerGrey);
  font-weight: 600;

  border: none;
}

table tbody.ant-table-tbody tr td {
  background: var(--greyAccent);
  border: none;
}

table tbody.ant-table-tbody tr :nth-child(1),
table tbody.ant-table-tbody tr :nth-child(3),
table tbody.ant-table-tbody tr :nth-child(4),
table thead.ant-table-thead tr :nth-child(1),
table thead.ant-table-thead tr :nth-child(3),
table thead.ant-table-thead tr :nth-child(4) {
  text-align: right;
}

.tableScore{
  padding-bottom: 50px;
}
.loading-container {
  padding: 8em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-circle {
  border: 6px solid var(--greyAccent);
  border-radius: 50%;
  border-top: 6px solid var(--orangeAccent);
  width: 40px;
  height: 40px; /* Safari */
  animation: spin 1500ms linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  color: var(--greyAccent);
  font-size: 1.602rem;
  padding-bottom: 0;
  margin-left: 0.5em;
}

.container {
  text-align: center;
}

.app-about a {
  text-decoration: underline;
}

.team-header {
  padding-top: 1em;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3em;
}

.team-icon {
  font-size: 3em;
  padding-bottom: 0.5em;
}

.team-member {
  padding: 1em;
  width: 50%;
}

.team-name {
  padding-bottom: 0;
}

@media (min-width: 1000px) {
  .container {
    max-width: 900px;
    margin: 0 auto;
  }

  .team-container {
    padding: 2em;
    display: flex;
    flex-wrap: nowrap;
  }

  .app-about {
    font-size: 1rem;
  }
}

.container-404 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2em 0;
}

.number-4 {
  font-size: 6rem;
  font-family: "Luckiest guy";
  font-weight: bold;
  color: var(--lightAccent);
}

.number-4:first-child {
  margin-right: 0.2em;
}

.not-found-text {
  font-size: 1.125rem;
  text-align: center;
}

.hometitle {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}
.homesubtitle {
  color: var(--greyAccent);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 2rem;
}
.homebtn {
  color: var(--darkAccent);
  margin-top: 0.25rem;
  height: 3.75rem;
  font-size: 1.125rem;
  font-weight: bold;
  width: 10.625rem;
  height: 3.125rem;
  cursor: pointer;
  margin-right: 0.625rem;
}
.getstarted,
.playnow {
  background-color: var(--darkerOrange);
  /* color: var(--lightAccent); */
}
.getstarted:hover,
.playnow:hover {
  background: var(--orangeAccent);
}
.white {
  background-color: var(--greyAccent);
  
}
.white:hover {
  background: var(--greyAccent);
  /* color: var(--lightAccent); */
}
.buttoncontainer1,
.buttoncontainer2 {
  display: flex;
  flex-direction: row;
}
.hometitle-container {
  margin-top: 3.125rem;
  margin-bottom: 0.75rem;
  max-width: 800px;
}
.Home {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--background);
}

.theme-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
}

.main-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.hometitle-container {
  margin-bottom: 4rem;
  max-width: 800px;
  animation: fadeInUp 0.8s ease-out;
}

.title-wrapper {
  position: relative;
  margin-bottom: 2rem;
}

.hometitle {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.title-underline {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(45deg, #e67e22, #d35400);
  border-radius: 2px;
}

.homesubtitle {
  color: var(--greyAccent);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.cta-text {
  color: #e67e22;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.home-buttonsContainer {
  display: flex;
  justify-content: center;
  animation: fadeInUp 1s ease-out 0.3s;
  animation-fill-mode: both;
}

.buttoncontainer1,
.ButtonOptions {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.btn.homebtn,
.ButtonOptions button {
  position: relative;
  min-width: 220px;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  background: rgba(70, 70, 70, 1);
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  box-shadow: none;
  outline: none;
}

/* Desktop hover effects */
@media (hover: hover) and (pointer: fine) {
  .btn.homebtn,
  .ButtonOptions button {
    transition: all 0.3s ease;
  }

  .btn.homebtn:hover,
  .ButtonOptions button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(244, 123, 63, 0.4);
    background: #FF8B4F;
  }
}

/* Mobile touch feedback */
@media (hover: none) and (pointer: coarse) {
  .btn.homebtn,
  .ButtonOptions button {
    transition: none;
    animation: none;
    transform: none !important;
    filter: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    
    &:active {
      background: rgba(60, 60, 60, 1);
      opacity: 1;
      box-shadow: none;
      transform: none !important;
      outline: none;
    }
    
    &:hover {
      background: rgba(70, 70, 70, 1);
    }
  }
}

/* Mobile layout adjustments */
@media screen and (max-width: 768px) {
  .buttoncontainer1,
  .ButtonOptions div {
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .btn.homebtn,
  .ButtonOptions button {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

/* iOS specific optimizations */
@supports (-webkit-touch-callout: none) {
  .btn.homebtn,
  .ButtonOptions button {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    filter: none;
    -webkit-filter: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
    
    &:active {
      background: rgba(60, 60, 60, 1);
      opacity: 1;
      box-shadow: none;
      filter: none;
      -webkit-filter: none;
    }
  }
}

.getstarted {
  background-color: #e67e22;
  color: white !important;
  position: relative;
  overflow: hidden;
}

.getstarted:hover {
  background-color: #d35400;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(230, 126, 34, 0.2);
}

.tutorial {
  background-color: var(--greyAccent);
  /* color: #e67e22 !important; */
  /* border: 2px solid #e67e22 !important; */
}

.tutorial:hover {
  background-color: rgba(230, 126, 34, 0.05) !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(230, 126, 34, 0.1);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.herologo {
  margin-top: -26.25rem;
  margin-left: 1.563rem;
}
.clocklogo {
  margin-top: -11.25rem;
  margin-left: 15.875rem;
  /* border-radius: 50%; */
}
.imagecontainer {
  /* display: flex;
  flex-direction: column; */
  margin-left: 5rem;
}

@media screen and (max-width: 1000px) {
  .herologo,
  .imageex {
    display: none;
  }
  .clocklogo {
    margin-bottom: -15.625rem;
    margin-left: 0.625rem;
  }
  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
  }
  .buttoncontainer1,
  .buttoncontainer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .homebtn {
    margin-bottom: 0.25rem;
  }
  .theme-toggle {
    margin-top: 25px;
  }
}
.theme-toggle i {
  font-size: 2rem;
  cursor: pointer;
}

.theme-toggle {
  margin-right: 10rem;
  margin-bottom: 11px;
  margin-top: -2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .theme-toggle {
    margin-top: 25px;
  }
  /* .Home {
    margin-left: 1rem;
  } */
}

/* Add these new styles for the animated arrow */
.scroll-arrow {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  cursor: pointer;
  animation: bounce 2s infinite;
}

.arrow {
  width: 30px;
  height: 30px;
  border-right: 4px solid #e67e22;
  border-bottom: 4px solid #e67e22;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.scroll-arrow:hover .arrow {
  transform: rotate(45deg) scale(1.1);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Update button text styles to match the image */
.btn.homebtn {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

.getstarted {
  background-color: rgba(255, 255, 255, 0.2) !important;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: none !important;
  color: white !important;
}

.tutorial {
  background-color: rgba(255, 255, 255, 0.2) !important;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: none !important;
  color: white !important;
}

.getstarted:hover, .tutorial:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Ensure smooth scrolling for all devices including iOS */
html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling for iOS */
}

/* For iOS devices specifically */
@supports (-webkit-touch-callout: none) {
  html, body {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  
  .Home {
    -webkit-overflow-scrolling: touch;
  }
  
  /* Target the specific scrollable container if needed */
  .main-content {
    -webkit-overflow-scrolling: touch;
  }
}

/* Update media queries for better mobile experience */
@media screen and (max-width: 768px) {
  .scroll-arrow {
    margin: 0.3rem 0;
  }
  
  .arrow {
    width: 20px;
    height: 20px;
  }
}

.btn.homebtn {
  /* Base button styles */
  position: relative;
  padding: 15px 30px;
  border-radius: 50px;
  background: #F47B3F; /* Solid orange color */
  border: none;
  box-shadow: 0 4px 8px rgba(244, 123, 63, 0.3); /* Subtle shadow */
  
  /* Text styles */
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  
  /* Container properties */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Animation */
  transition: all 0.3s ease;
}

.btn.homebtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(244, 123, 63, 0.4); /* Enhanced shadow on hover */
  background: #FF8B4F; /* Slightly lighter on hover */
}

/* Add this if you want the glowing animation */
@keyframes glow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btn.homebtn {
  background-size: 200% 200%;
  animation: glow 3s ease infinite;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .buttoncontainer1,
  .ButtonOptions {
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
}

.homebtn.getstarted {
  min-width: 200px;  /* or whatever width is needed to fit the text */
  white-space: nowrap;  /* prevents text from wrapping */
}

/* Feature Cards Styles */
.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  text-align: left;
  transition: transform 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  isolation: isolate; /* Prevent backdrop-filter stacking */ /* Force GPU acceleration */
  transform: translateZ(0);
}

.feature-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.feature-card i {
  font-size: 2rem;
  color: #F47B3F;
  margin-bottom: 1rem;
}

.feature-card h3 {
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .feature-cards {
    grid-template-columns: 1fr;
    padding: 2rem 1rem;
  }
  
  .feature-card {
    padding: 1.5rem;
  }
}

/* Update the hover effects to only apply on non-touch devices */
@media (hover: hover) {
  .btn.homebtn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(244, 123, 63, 0.4);
    background: #FF8B4F;
  }

  .feature-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
  }

  .getstarted:hover, .tutorial:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
}

/* Remove transform effects for touch devices */
@media (hover: none) {
  .btn.homebtn:active,
  .feature-card:active,
  .getstarted:active,
  .tutorial:active {
    background-color: rgba(255, 255, 255, 0.3);
    transform: none;
  }
}

/* Fix for the animation glitch */
.btn.homebtn {
  /* Remove the infinite animation that might cause issues */
  animation: none;
  /* Keep other existing styles */
  background: #F47B3F;
  position: relative;
  padding: 15px 30px;
  /* ... other existing styles ... */
}

/* Add the coming soon badge style */
.coming-soon-badge {
  background: #F47B3F;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 8px;
  font-weight: 600;
  vertical-align: middle;
}

/* Remove hover effects for mobile touch devices */
@media (hover: none) and (pointer: coarse) {
  .feature-card:hover,
  .feature-card:active,
  .btn.homebtn:hover,
  .btn.homebtn:active {
    transform: none !important;
  }
}

/* Keep hover effects only for devices that support hover */
@media (hover: hover) and (pointer: fine) {
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  .btn.homebtn:hover {
    transform: translateY(-2px);
  }
}

/* Remove any hover effects during scroll on mobile */
@media (hover: none) and (pointer: coarse) {
  .feature-card {
    transition: none;
    -webkit-backdrop-filter: none;
            backdrop-filter: none; /* Disable backdrop filter on mobile */
    background: rgba(255, 255, 255, 0.1); /* Use solid background instead */
  }
  
  .feature-card:hover,
  .feature-card:active,
  .feature-card:focus {
    transform: none !important;
    background: rgba(255, 255, 255, 0.1) !important;
  }
}

/* Prevent iOS Safari scroll issues */
@supports (-webkit-touch-callout: none) {
  .feature-card {
    backface-visibility: hidden;
  }
  
  .feature-cards {
    transform: translateZ(0);
  }
}

/* Base button styles remain the same but move hover effects to desktop-only */
.btn.homebtn {
  -webkit-tap-highlight-color: transparent; /* Prevent tap highlight on mobile */ /* Hardware acceleration */
  transform: translateZ(0);
  will-change: transform; /* Optimize for animations */
  transition: none; /* Remove transitions on mobile by default */
}

/* Desktop-only hover effects */
@media (hover: hover) and (pointer: fine) {
  .btn.homebtn {
    transition: all 0.3s ease; /* Re-enable transitions for desktop */
  }

  .btn.homebtn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(244, 123, 63, 0.4);
    background: #FF8B4F;
  }

  .getstarted:hover, 
  .tutorial:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
}

/* Mobile touch device specific styles */
@media (hover: none) and (pointer: coarse) {
  .btn.homebtn,
  .getstarted,
  .tutorial {
    transform: none !important;
    transition: none !important;
    animation: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  .btn.homebtn:active,
  .getstarted:active,
  .tutorial:active {
    background: #E66B2F !important; /* Darker shade for active state */
    transform: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .btn.homebtn,
  .getstarted,
  .tutorial {
    backface-visibility: hidden;
    perspective: 1000;
  }
}

/* BASED ON BOMB BEING 180 x 180 */

.bomb-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 220px;
  padding-top: 10px;
  padding-bottom: 50px;
}
svg.bomb {
  display: block;
  position: absolute;
  margin: auto;

  transform: translateX(18px);
}

p.bomb-time,
p.game-over {
  font-family: "Roboto Mono", monospace;
  color: black;
  font-size: 28px;
  z-index: 5;
  line-height: 1;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
p.game-over {
  font-family: "Poppins";
  font-weight: 800;
  color: white;
}

/* Add media query for mobile devices */
@media screen and (max-width: 480px) {
  p.bomb-time,
  p.game-over {
    font-size: 24px;  /* Smaller font size for mobile */
  }
}

.EndScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.endscreen-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2.5rem;
}
.title-endscreen {
  margin-top: -2.5rem;
}
.endscreenScore {
  color: var(--yellowAccent);
  font-weight: bold;
  font-size: 4.375rem;
  animation: fade-in ease-in 4s;
}
.button-endscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
}
.endbtn {
  height: 3.75rem;
  font-size: 1rem;
  font-weight: bold;
  width: 10.625rem;
  height: 3.125rem;
  cursor: pointer;
  justify-content: center;
}
.avatarBackground {
  width: 12.5rem;
}
.endscreen-avatar-container {
  margin-bottom: 6.375rem;
  /* margin-bottom: 4.375rem;6.375rem */
}

@media screen and (max-width: 1000px) {
  .endscreen-avatar-container {
    margin-top: 5rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.howtoplay-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 100px);
}

.header {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
  margin-bottom: 1em;
}

.rules {
  width: 100%;
  max-width: 900px;
  margin-bottom: 2em;
}

.rule-container {
  display: flex;
  align-items: flex-start;
  grid-gap: 1.5em;
  gap: 1.5em;
  margin-bottom: 2em;
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5em;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.rule-container:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.number-container {
  flex-shrink: 0;
  padding: 0.5em;
}

.rule {
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.6;
  margin: 0;
  color: #fff;
}

.rule-details {
  margin-top: 1em;
  padding-left: 1.5em;
}

.rule-details li {
  margin-bottom: 0.5em;
  list-style-type: none;
  position: relative;
}

.rule-details li::before {
  content: "•";
  position: absolute;
  left: -1em;
  color: #FF8C42;
}

@media (max-width: 768px) {
  .howtoplay-page {
    padding: 1em;
  }

  .rule-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em;
  }

  .number-container {
    margin: 0 0 1em 0;
  }

  .rule-details {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .header {
    margin-bottom: 0.5em;
  }

  .rule-container {
    margin-bottom: 1em;
  }
}

.coming-soon-badge {
  background: #F47B3F;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 8px;
  font-weight: 600;
  vertical-align: middle;
}

/* Remove ALL hover effects on touch devices */
@media (hover: none) and (pointer: coarse) {
  .rule-container:hover,
  .rule-container:active,
  .number-container:hover,
  .number-container:active,
  .rule:hover,
  .rule:active,
  button:hover,
  button:active,
  a:hover,
  a:active,
  .feature-card:hover,
  .feature-card:active {
    transform: none !important;
  }
}

/* Only apply hover effects on devices that support hover */
@media (hover: hover) and (pointer: fine) {
  .rule-container:hover {
    transform: translateY(-2px);
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  button:hover {
    transform: translateY(-2px);
  }
}

