/* BASED ON BOMB BEING 180 x 180 */

.bomb-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 220px;
  padding-top: 10px;
  padding-bottom: 50px;
}
svg.bomb {
  display: block;
  position: absolute;
  margin: auto;

  transform: translateX(18px);
}

p.bomb-time,
p.game-over {
  font-family: "Roboto Mono", monospace;
  color: black;
  font-size: 28px;
  z-index: 5;
  line-height: 1;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
p.game-over {
  font-family: "Poppins";
  font-weight: 800;
  color: white;
}

/* Add media query for mobile devices */
@media screen and (max-width: 480px) {
  p.bomb-time,
  p.game-over {
    font-size: 24px;  /* Smaller font size for mobile */
  }
}
