.signup-container {
  padding: 1.5rem;
  max-width: 450px;
  margin: 1.5rem auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  -webkit-tap-highlight-color: transparent;
}

/* Title styling */
.signup-container h1 {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
}

/* Form item spacing */
.ant-form-item {
  margin-bottom: 0.8rem !important;
}

/* Button styling */
.btn.btn-primary {
  background: linear-gradient(45deg, #e67329, #ff8f4d) !important;
  border: none !important;
  height: 40px !important;
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
}

.btn.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(75, 204, 163, 0.2) !important;
}

/* Sign up text styling */
.form-text {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem !important;
  margin-top: 0.5rem !important;
}

.form-link {
  color: #e67329;
  font-weight: 600;
}

.form-link:hover {
  color: #ff8f4d;
  text-decoration: underline;
}

/* Desktop-specific styles */
@media (hover: hover) and (pointer: fine) {
  .signup-container {
    transition: all 0.3s ease;
  }

  .btn.btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(75, 204, 163, 0.2) !important;
  }

  .form-link:hover {
    color: #ff8f4d;
    text-decoration: underline;
  }
}

/* Mobile and touch device optimizations */
@media (hover: none) and (pointer: coarse) {
  .signup-container {
    margin: 1rem auto;
    transition: none !important;
    backdrop-filter: none; /* Disable for better performance */
    background: rgba(255, 255, 255, 0.15); /* Solid background fallback */
  }

  .btn.btn-primary,
  .form-link {
    transition: none !important;
    transform: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  .btn.btn-primary:active {
    background: #e67329 !important;
    transform: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  .form-link:active {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .signup-container {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
  }

  .ant-form-item,
  .ant-input,
  .btn.btn-primary {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  /* Prevent scroll issues */
  .ant-form {
    -webkit-overflow-scrolling: touch;
  }
}

/* Small screen specific adjustments */
@media (max-width: 380px) {
  .signup-container {
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 12px;
  }

  .signup-container h1 {
    font-size: 1.8rem;
  }

  .ant-form-item {
    margin-bottom: 0.75rem !important;
  }

  .btn.btn-primary {
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
  }
}

/* Add this to style the placeholder text */
.ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

