.howtoplay-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 100px);
}

.header {
  font-weight: 800;
  font-size: 2.0rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #e67329, #ff944d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
  margin-bottom: 1em;
}

.rules {
  width: 100%;
  max-width: 900px;
  margin-bottom: 2em;
}

.rule-container {
  display: flex;
  align-items: flex-start;
  gap: 1.5em;
  margin-bottom: 2em;
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5em;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.rule-container:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.number-container {
  flex-shrink: 0;
  padding: 0.5em;
}

.rule {
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.6;
  margin: 0;
  color: #fff;
}

.rule-details {
  margin-top: 1em;
  padding-left: 1.5em;
}

.rule-details li {
  margin-bottom: 0.5em;
  list-style-type: none;
  position: relative;
}

.rule-details li::before {
  content: "•";
  position: absolute;
  left: -1em;
  color: #FF8C42;
}

@media (max-width: 768px) {
  .howtoplay-page {
    padding: 1em;
  }

  .rule-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em;
  }

  .number-container {
    margin: 0 0 1em 0;
  }

  .rule-details {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .header {
    margin-bottom: 0.5em;
  }

  .rule-container {
    margin-bottom: 1em;
  }
}

.coming-soon-badge {
  background: #F47B3F;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 8px;
  font-weight: 600;
  vertical-align: middle;
}

/* Remove ALL hover effects on touch devices */
@media (hover: none) and (pointer: coarse) {
  .rule-container:hover,
  .rule-container:active,
  .number-container:hover,
  .number-container:active,
  .rule:hover,
  .rule:active,
  button:hover,
  button:active,
  a:hover,
  a:active,
  .feature-card:hover,
  .feature-card:active {
    transform: none !important;
  }
}

/* Only apply hover effects on devices that support hover */
@media (hover: hover) and (pointer: fine) {
  .rule-container:hover {
    transform: translateY(-2px);
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  button:hover {
    transform: translateY(-2px);
  }
}
