header {
  padding: 1em 1em;
}

.navbar {
  /* position: relative; */
  position: relative;
}

.app-logo {
  position: absolute;
  left: 1em;
  top: 0.7em;
  z-index: 10;
  cursor: pointer;
}

.navbar button {
  position: fixed;
  text-align: right;
  right: 1em;
  top: 1em;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  border: none;
}

.navbar-icon {
  font-size: 1.8rem;
}

.menuNav {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  z-index: 9;
  background-color: var(--darkerGrey);
  transition: width 400ms cubic-bezier(0.175, 0.885, 0.32, 1.475);
}

.menuNav.showMenu {
  width: 100%;
}

.menuNav a:first-child {
  margin-top: 5em;
}

.navbar-item {
  padding: 1em;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.navbar a {
  display: block;
}
/* .form-input {
  width: "450px";
} */
@media (min-width: 500px) {
  header {
    padding: 1.5em 2em;
  }
  /* .form-input {
    width: "450px";
    margin-left: 2rem;
    margin-right: 2rem;
  } */
  .navbar button {
    right: 2em;
    top: 1.5em;
  }

  .navbar-icon {
    font-size: 2.2rem;
  }

  .app-logo {
    left: 2em;
    top: 1.5em;
  }
}

@media (min-width: 1000px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
  }

  .navbar {
    align-self: flex-end;
    margin-bottom: 0.5em;
    margin-top: 0.4rem;
    margin-right: 2.5em;
  }

  .navbar button {
    display: none;
  }

  .menuNav {
    position: relative;
    height: auto;
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    background-color: inherit;
  }

  .menuNav a:first-child {
    margin-top: 0;
  }

  .navbar-item {
    width: auto;
    padding: 0;
    margin-right: 0.8em;
    font-size: 1rem;
  }

  .navbar a {
    display: inline-block;
  }

  .app-logo {
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    cursor: pointer;
  }
}

@media (max-width: 998px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--darkAccent);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 27px;
  }
  .app {
    padding-top: 4.7em;
  }
  .navbar {
    height: 38.4px;
    display: flex;
    align-items: center;
    margin-top: 13.5px;
  }

  .navbar button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38.4px;
  }

  .navbar-icon {
    font-size: 1.8rem;
    color: var(--lightAccent);
  }

  /* Adjust app padding to match header height */
  /* .app {
    padding-top: 72px;
  } */

  .menuNav a,
  .menuNav div {
    display: block;
    margin: 0;  /* Reset all margins */
    padding: 1rem;  /* Use padding for spacing */
  }

  /* Target DropdownOptions specifically */
  .menuNav div.navbar-item {
    margin: 0;
    padding: 0;  /* Remove padding from the container */
  }

  /* First item spacing from top */
  .menuNav a:first-child {
    margin-top: 5rem;
  }

  /* Remove any inherited margins */
  .navbar-item {
    margin: 0;
  }

  /* Target dropdown options buttons/links specifically */
  .menuNav div.navbar-item button,
  .menuNav div.navbar-item a {
    padding: 1rem;
    margin: 0;
  }

  .menuNav {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    overflow: hidden;
    z-index: 9;
    background-color: var(--darkerGrey);
    display: flex;
    flex-direction: column;
  }

  .menuNav.showMenu {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;  /* Add padding at bottom */
    overflow-y: auto;      /* Enable scrolling when needed */
  }

  .menuNav-main {
    flex: 1;
    padding-top: 5em;
    display: flex;
    flex-direction: column;
    min-height: min-content;
  }

  .menuNav-bottom {
    padding-bottom: 2em;
    margin-top: auto;  /* Push to bottom */
  }

  .menuNav a:first-child {
    margin-top: 5em;  /* Reduced from 5em */
  }

  .menuNav a,
  .menuNav div {
    padding: 0.8rem;
  }
}
