.container {
  text-align: center;
}

.app-about a {
  text-decoration: underline;
}

.team-header {
  padding-top: 1em;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3em;
}

.team-icon {
  font-size: 3em;
  padding-bottom: 0.5em;
}

.team-member {
  padding: 1em;
  width: 50%;
}

.team-name {
  padding-bottom: 0;
}

@media (min-width: 1000px) {
  .container {
    max-width: 900px;
    margin: 0 auto;
  }

  .team-container {
    padding: 2em;
    display: flex;
    flex-wrap: nowrap;
  }

  .app-about {
    font-size: 1rem;
  }
}
